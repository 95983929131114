export interface ILoginRequest {
  username?: string
  password?: string
}

export class LoginRequest {
  public username: string = ''
  public password: string = ''

  constructor ({ username = '', password = '' }: ILoginRequest) {
    this.username = username
    this.password = password
  }

  public get isValid() {
    return !!this.username && !!this.password
  }
}
